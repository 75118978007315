import styled from "styled-components"
import variables from "../../data/variables"

export const Intro = styled.div`
  padding: 8rem 0 4rem 0;
  text-align: left;
`

export const Title = styled.h1`
  font-size: 3rem;
  letter-spacing: -0.1rem;
  font-family: "Poppins";
`

export const ArticlePost = styled.article`
  margin-bottom: 5rem;
  padding-bottom: 1rem;
  max-width: 60rem;
  margin: auto;
  img[src$="imgresponsive"] {
    max-width: 80%;
  }
`
export const SmallText = styled.small`
  font-size: 0.89rem;
  padding-right: 10px;
  font-family: "Inter";
  > span {
    padding-left: 5px;
  }
`
export const ArticleBody = styled.div`
  margin-top: 5rem;
  p {
    font-size: 1.2rem;
    font-family: "Inter";
    font-weight: 400;
    line-height: 2.2rem;
    color: #575757;
    margin: 2rem 0;
  }
  ul{
    font-size: 1.2rem;
    line-height: 1.5;
    color: rgb(87, 87, 87);
  }
  h2 {
    font-size: 1.7rem;
    font-family: "Poppins";
    margin: 3rem 0 0 0;
  }
  & img {
    box-shadow: rgb(0 0 0 / 2%) 1.02388px 1.54966px 1.4113px -22px, rgb(0 0 0 / 3%) 2.46054px 3.72406px 3.39155px -22px, rgb(0 0 0 / 4%) 4.63297px 7.01207px 6.38599px -22px, rgb(0 0 0 / 4%) 8.26443px 12.5083px 11.3915px -22px, rgb(0 0 0 / 5%) 15.4577px 23.3955px 21.3066px -22px, rgb(0 0 0 / 7%) 37px 56px 51px -22px !important;
  }
`

export const NaviagtionList = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5rem;
  grid-row-gap: 5rem;
  list-style: none;
  padding: 0;
  border-top: 1px solid ${variables.primary}};
  border-bottom: 1px solid ${variables.primary}};
`
export const NaviagtionLi = styled.li`
  padding: 2rem 0;
  &:last-child {
    text-align: right;
  }
  a {
    font-size: 1.3rem;
    font-family: "Poppins";
  }
`
